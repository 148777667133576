<template>
    <div class="d-flex align-items-center mb-2 mt-2 mx-3 flex-wrap flex-md-nowrap">
        <div class="d-block d-md-flex w-100">
            <CDropdown :key="`${selected.length}-${type}`" color="primary" class="mr-2 mb-2 w-md-auto w-100 d-flex"
                       style="min-width: 200px">
                <CDropdownToggle :key="`${selected.length}`" color="primary" variant="outline"
                                 :disabled="!selected.length"
                                 class="text-start d-flex align-items-center justify-content-between">
                    Bulk Action
                </CDropdownToggle>
                <CDropdownMenu>
                    <CDropdownItem @click="download">Download</CDropdownItem>
                </CDropdownMenu>
            </CDropdown>
            <CInputGroup class="mr-2 mb-2 w-md-auto w-100">
                <CInputGroupText>
                    <CIcon name="cil-search"/>
                </CInputGroupText>
                <CFormInput v-model="search" placeholder="Search" maxlength="200"/>
            </CInputGroup>
        </div>
    </div>
    <div class="px-2 documents-table">
        <SmartTable :items="tasks" :items-per-page="parseInt(perPage)" :columns="fields" selectable="id"
                    :sorter-value="sort" :selected-items="selected" :loading="loading" :active-page="activePage"
                    :per-page="perPage" :pages="pages" clear-text="No data to show" @sorter-change="onSort"
                    @selected-items-change="setSelected" @update-active-page="setActivePage"
                    @update-per-page="setPerPage">
            <template #status="{ item, column }">
                <CBadge v-if="column.key === 'file_name'" color="primary">{{ item.status }}</CBadge>
            </template>
            <template #external_company_name="{ item, column }">
                {{ item.external_company.name }}
            </template>
            <template #internal_assignee_name="{ item, column }">
                {{ item.internal_assignee.full_name }}
            </template>
            <template #external_assignee_name="{ item, column }">
                {{ item.external_assignee.full_name }}
            </template>
            <template #clear_text>
                <div class="table__clear d-flex align-items-center justify-content-center mb-2 w-100">
                    <CIcon class="table__clear__icon" size="6xl" name="cilDescription"/>
                    <div class="table__clear__title">No documents yet</div>
                    <div class="table__clear__title-sub">Add document from library or from computer</div>
                </div>
            </template>
        </SmartTable>
        <div v-if="loadingTable" class="d-flex align-items-center justify-content-center mb-2"></div>
    </div>
</template>

<script>
import table from '@/mixin/table'
import Star from '@/components/Star'
import onTimeoutSearch from '@/mixin/searchTimeout'
import Tasks from "@/api/v2/endpoints/Tasks";
import {DONE_TASKS, PENDING_APPROVAL} from "@/domain/Entities/Task/taskStatuses";
import dateFormater from "@/mixin/dateFormater";
import {
    ACKNOWLEDGE_TEMPLATE_TYPE,
    CONTRACT_TEMPLATE_TYPE,
    REQUEST_DOCUMENT_TEMPLATE_TYPE
} from "@/domain/Entities/Template/templateTypes";
import Projects from "@/api/v2/endpoints/Projects";

const PENDING_TYPE = 'pending';
const COMPLETED_TYPE = 'completed';

export default {
    components: {Star},
    mixins: [table, onTimeoutSearch, dateFormater],
    inject: ['downloadToast'],
    props: {
        type: {
            type: String,
            validator: (value) => [PENDING_TYPE, COMPLETED_TYPE].includes(value),
        },
    },
    data(self) {
        return {
            loading: false,
            projectId: null,
            project: null,
            tasks: [],
            fields: [
                {
                    key: 'file_name',
                    label: 'File Name',
                    click: (item) => self.goToView(item),
                },
                {
                    key: 'name',
                    label: 'Task Name',
                    click: (item) => self.goToView(item),
                },
                {
                    key: 'external_company_name',
                    label: 'Company',
                },
                {
                    key: 'due_date',
                    label: 'Due Date',
                    format: (value) => {
                        return self.formatDate(value)
                    },
                },
                {
                    key: 'priority',
                    label: 'Priority',
                },
                {
                    key: 'internal_assignee_name',
                    label: 'Internal Owner',
                },
                {
                    key: 'external_assignee_name',
                    label: 'Assigned To',
                },
            ],
        };
    },
    computed: {
        taskStatuses() {
            return this.type === COMPLETED_TYPE ? DONE_TASKS : [PENDING_APPROVAL];
        }
    },
    watch: {
        search() {
            this.onTimeoutSearch(() => this.loadTasks());
            this.selected = [];
        },
        type() {
            this.loadTasks()
            this.selected = []
        },
    },
    created() {
        this.projectId = this.$route.params.id;

        this.loadProject();
        this.loadTasks();
    },
    methods: {
        loadProject() {
            Projects
                .show(this.projectId)
                .then(response => this.project = response.data);
        },
        loadTasks() {
            this.toggleLoading();

            Tasks
                .index({
                    project_id: this.projectId,
                    only_statuses: this.taskStatuses,
                    task_data: 'id,type,file_name,status,name,due_date,priority',
                    with_internal_assignee: 'full_name',
                    with_external_assignee: 'full_name',
                    with_external_company: 'name',
                    search: this.search,
                })
                .then(response => this.tasks = response.data)
                .finally(() => this.toggleLoading())
        },
        goToView(item) {
            if (item.type === REQUEST_DOCUMENT_TEMPLATE_TYPE) {
                this.$router.push({
                    name: 'TaskRequest',
                    params: {id: this.projectId, task_id: item.id},
                })
            } else if (item.type === CONTRACT_TEMPLATE_TYPE) {
                this.$router.push({
                    name: 'TaskContracts',
                    params: {id: this.projectId, task_id: item.id},
                })
            } else if (item.type === ACKNOWLEDGE_TEMPLATE_TYPE) {
                this.$router.push({
                    name: 'TaskAcknowledge',
                    params: {id: this.projectId, task_id: item.id},
                })
            }
        },
        download() {
            const documentName = 'documents - ' + this.formatDate(new Date(), 'YYYY-MM-DD');

            Tasks
                .downloadDocuments({task_ids: this.selected, project_id: this.projectId})
                .then(response => this.downloadToast.open(`download.${response.token}`, documentName))
        },
        toggleLoading() {
            this.loading = !this.loading;
        }
    },
}
</script>

<style lang="scss" scoped>
.table__clear {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 30px 60px;
    background: #f5f7f9;

    &__icon {
        color: #b1b7c1;
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 18px;
        color: #303c54;

        &-sub {
            margin-bottom: 35px;
            font-weight: 400;
            font-size: 14px;
            color: #465967;
        }
    }
}
</style>
