import moment from "moment";

export default {
    methods: {
        formatDate(date = null, format = 'MM/DD/YYYY') {
            return date ? moment.utc(date).local().format(format) : '';
        },
        formatDateTime(date = null, format = 'MM/DD/YYYY HH:mm:ss') {
            return date ? moment.utc(date).local().format(format) : '';
        }
    }
}
